body {
  overflow: hidden;
}

div#root {
  overflow-y: auto;
  -ms-overflow-style: none;
  height: 100%;
}

div#root::-webkit-scrollbar {
  display: none;
}

.header-container {
  position: fixed;
  top: 0;
  width: 100%;
  height: 111px;
  z-index: 10;
  background-color: white;
  border-radius: 5px;
  box-shadow:
    0 2px 4px 0 rgba(34, 36, 38, 0.12),
    0 2px 10px 0 rgba(34, 36, 38, 0.15);
}

.track-input-container {
  padding: 10px 20px;
}

.track-input-container-not-logged {
  padding: 5px 20px;
}

.remove-header-radius {
  border-radius: 0 !important;
}

.track-page-container {
  padding: 130px 20px 20px;
}

.delete-number-button {
  margin-left: 10px;
}

.ui.list > .item.list-row-padding {
  padding: 8px;
}

.number-list-section {
  cursor: pointer;
  font-size: 15px;
}

.track-number-info {
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  color: gray;
}

.ui.modal.track-numner-list-modal > .header {
  line-height: 1;
  margin: 8px;
}

.margin-0 {
  margin: 0 !important;
}

.ui.image.label.login-button {
  background-color: white;
}

.ui.label.login-button > img {
  height: 20px !important;
  margin-top: -4px;
  margin-left: -4px;
}

.logout-dropdown {
  bottom: 6px;
  margin-bottom: -8px;
}

.input-number {
  max-width: 185px;
}

.ui.image.service-logo {
  opacity: 1;
}

.ui.image.service-logo > img {
  height: 30px;
  width: auto;
}

.bhPosta .ui.label {
  background-color: #f2f8ff;
}

.posteSrpske .ui.label {
  background-color: #fff2f2;
}

.xExspress .ui.label {
  background-color: #efd6c5;;
}

.euroexpress .ui.label {
  background-color: #f7f0fa;
}
